<template>
    <div class="bursary-form">
      <h4 class="text-danger">
        North Mugirango 2025 Universities and colleges Students Bursary Application Form
      </h4>

     

      <form v-if="!isPreviewMode" @submit.prevent="previewForm">
        <!-- Student Information -->
        <div class="form-section">
          <h5 class="text-primary">Student Information</h5>
          <!-- colleges {{ colleges }} -->

          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="studentName">Name:</label>
              <input
                type="text"
                id="studentName"
                v-model="form.studentName"
                required
              />
            </div>
             <!-- <label for="gen">Gender:</label>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                  <select class="form-control" v-model="form.gender" required>
                     <option disabled value="">Select student Gender</option>
                     <option>Male</option>
                     <option>Female</option>
                     
                   </select>
                 </div> -->
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="gen">Gender:</label>
              <select v-model="form.gender" required>
                <option  disabled>Select</option>
                <option >Male</option>
                <option>Female</option>
               
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="yob">Year of Birth:</label>
              <input
                type="number"
                id="yob"
                v-model="form.yob"
                min="1900"
                max="2025"
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="idNumber">ID/Birth certificate Number:</label>
              <input
                type="text"
                id="idNumber"
                v-model="form.idNumber"
                required
                placeholder="ID Number"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div class="autocomplete-field">
                <label for="schoolName">School Name:</label>
                <input
                  type="text"
                  id="schoolName"
                  v-model="schoolName"
                  @input="filterSchoolSuggestions"
                  @focus="showSuggestions = true"
                  @blur="hideSuggestions"
                  placeholder="Start typing school name"
                  autocomplete="off"
                  required
                />
                <ul v-if="showSuggestions" class="autocomplete-suggestions">
                  <!-- <li
          v-for="(school, index) in filteredSchools"
          :key="index"
          @mousedown.prevent="selectSchool(school)"
        >
          {{ school }}
        </li> -->
                  <li
                    v-for="(school, index) in filteredSchools"
                    :key="index"
                    @mousedown.prevent="selectSchool(school.school_name)"
                  >
                    {{ school.school_name }}
                  </li>
                  <li v-if="filteredSchools.length === 0" class="no-match">
                    No match found. Press Enter to add: "{{ schoolName }}"
                  </li>
                </ul>
              </div>
              <!-- <label for="schoolName">School Name:</label>
                <input type="text" id="schoolName" v-model="form.schoolName" required /> -->
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="adminNumber">Registration Number:</label>
              <input
                type="text"
                id="adminNumber"
                v-model="form.adminNumber"
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="school_type">Institution Type:</label>
              <select id="school_type" v-model="form.school_type" required>
                <option  disabled>Select</option>
                <option >University</option>
                <option >College</option>
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="student_form">level of study:</label>
              <select id="student_form" v-model="form.student_form" required>
                <option value="" disabled>Select</option>
                <!-- <option >1st year</option> -->
                <!-- <option >2nd Year</option> -->
                <option >3rd Year</option>
                <option >4th year</option>
                <option >5th year</option>
                <option >6th year</option>
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="yearofadmission">Year of Admission:</label>
              <input
                type="number"
                id="yearofadmission"
                v-model="form.yearofadmission"
                min="1900"
                max="2025"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="student_form">Parents:</label>
              <select id="needy_status" v-model="form.needy_status" required>
                <option  disabled>Select</option>
                <option >Both Parents</option>
                <option >Has single parent</option>
                <option >Total Orphan</option>
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="student_form">Ward:</label>
              <select id="ward" v-model="form.ward" required>
                <option  disabled>Select</option>
                <option >Bokeira</option>
                <option >Bomwagamo</option>
                <option >Ekerenyo</option>
                <option >Itibo</option>
                <option >Magwagwa</option>
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="yearofadmission">SUB lOCATION:</label>
              <input type="text" id="location" v-model="form.sub_location" />
            </div>
          </div>
  
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="student_form">Dissability Status:</label>
              <select id="disability_status" v-model="form.disability_status" required>
                <option  disabled>Select</option>
                <option >No Dissability</option>
                <option >With Dissability</option>
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="student_form">Course of study:</label>
              <input type="text" id="location" v-model="form.course" />
  
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <label for="yearofadmission">Main Contact phone:</label>
              <input type="text" id="location" v-model="form.main_contact_number" />
            </div>
          </div>
        </div>
  
        <!-- Parent/Guardian Information -->
        <div class="form-section">
          <h5 class="text-primary">Parent/Guardian Information</h5>
          <div class="form-row">
            <!-- Mother -->
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <h5 class="text-secondary">Mother</h5>
              <label for="motherName">Name:</label>
              <input type="text" id="motherName" v-model="form.mother_names" />
  
              <label for="motherId">ID Number:</label>
              <input type="text" id="motherId" v-model="form.mother_id" />
  
              <label for="motherYob">Year of Birth:</label>
              <input
                type="number"
                id="motherYob"
                v-model="form.mother_yob"
                min="1900"
                max="2025"
              />
  
              <label for="motherPhone">Phone Number:</label>
              <input type="tel" id="motherPhone" v-model="form.mother_phone" />
            </div>
  
            <!-- Father -->
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <h5 class="text-secondary">Father</h5>
              <label for="fatherName">Name:</label>
              <input type="text" id="fatherName" v-model="form.father_names" />
  
              <label for="fatherId">ID Number:</label>
              <input type="text" id="fatherId" v-model="form.father_id" />
  
              <label for="fatherYob">Year of Birth:</label>
              <input
                type="number"
                id="fatherYob"
                v-model="form.father_yob"
                min="1900"
                max="2025"
              />
  
              <label for="fatherPhone">Phone Number:</label>
              <input type="tel" id="fatherPhone" v-model="form.father_phone" />
            </div>
  
            <!-- Guardian -->
            <!-- <div class="form-group parent-group"> -->
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <h5 class="secondary">Guardian</h5>
              <label for="guardianName">Name:</label>
              <input type="text" id="guardianName" v-model="form.guardian_name" />
  
              <label for="guardianId">ID Number:</label>
              <input type="text" id="guardianId" v-model="form.guardian_id" />
  
              <label for="guardianYob">Year of Birth:</label>
              <input
                type="number"
                id="guardianYob"
                v-model="form.guardian_yob"
                min="1900"
                max="2025"
              />
  
              <label for="guardianPhone">Phone Number:</label>
              <input
                type="tel"
                id="guardianPhone"
                v-model="form.guardian_phone"
              />
            </div>
          </div>
        </div>
        <br />
  
        Brief honest narration of parent/guardian occupation and why you deserve
        the bursary
  
        <div class="">
          <div class="">
            <textarea
              cols="1"
              rows="3"
              class="form-control"
              placeholder="Parent/Guardian occupation - Give us your story"
              v-model="form.occupation"
            ></textarea>
          </div>
        </div>
        <br />
  
        <div class="form-row">
          <div class="form-actions col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <button type="submit">Preview Application</button>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <router-link class="btn btn-secondary" to="/apply/cdf">
              Go back to instructions</router-link
            >
          </div>
        </div>
  
        <!-- <div class="form-actions">
          <button type="submit">Preview Application</button>
        </div> -->
      </form>
  
      <div v-else class="preview-section">
        <h3>Preview of Your Application</h3>
  
        <base-card>
          <div id="element-to-convert">
            <div class="row">
              <div class="col-lg-3">
                <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
              </div>
  
              <div class="col-lg-9">
                National Government Constituency Development Fund
                <br />
                North Mugirango constituency,
                <br />
                NG- CDF Office Building, Ekerenyo, Nyamira county
                <br />
                Email: ngcdfnorthmugirango@ngcdf.go.ke Website: www.cdf.go.ke
              </div>
            </div>
            <small>
              <div v-for="form in forms" :key="form.id">
                <!-- <b class="text-danger fst-italic">FORM SERIAL NUMBER:  C{{ form.serialId }} </b>  -->
              </div>
            </small>
            <center>
              <b class="text-danger text-center">
                Bursary Application form for Universities and colleges
              </b>
            </center>
            ...................................................................................................................................................................
            <br />
            <!-- {{ forms }} -->
  
            <b class="text-danger">STUDENT DETAILS</b>
            <div class="row">
              <div class="col-lg-5">
                Student name:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.studentName }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-3">
                Id Number:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.idNumber }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Main contact phone:
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.main_contact_number }}
                  </p>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-5">
                Sub location
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.sub_location }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-3">
                ward
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.ward }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                Needy status:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.needy_status }}
                  </p>
                </div>
              </div>
             
            </div>
  
            <div class="row">
              <div class="col-lg-5">
                Institution Name:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ schoolName }}
                  </p>
                </div>
              </div>
  
              <div class="p col-lg-3">
                Institution type
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.school_type }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Year of Admission
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.yearofadmission }}
                  </p>
                </div>
              </div>
           
            </div>
  
            <div class="row">
              <div class="col-lg-5">
                Admission number:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.adminNumber }}
                  </p>
                </div>
              </div>
  
              <div class="p col-lg-3">
                Class
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.student_form }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Dissability status
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.disability_status }}
                  </p>
                </div>
              </div>
           
            </div>
  
            <!-- <b class="text-danger">Insitution Details</b> -->
  
            <div class="row"></div>
            <div class="row"></div>
            <b class="text-danger">PARENT DETAILS</b>
  
            <div class="row">
              <div class="col-lg-5">
                Mother name:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.mother_names }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-3">
                Mother Id:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.mother_id }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Mother year of Birth:
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.mother_yob }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5">
                Father names:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.father_names }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-3">
                Father Id:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.father_id }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Father year of Birth:
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.father_yob }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-5">
                Guardian Names:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.guardian_name }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-3">
                Guardian Id:
                <div>
                  <p class="text-capitalize text-primary">
                    {{ form.guardian_id }}
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4">
                Guardian year of Birth:
                <div class="typed">
                  <p class="text-capitalize text-primary">
                    {{ form.guardian_yob }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">Ocupation and narration</div>
              <div class="col-lg-8 typed">
                <p class="text-capitalize text-danger">
                   {{ form.occupation }}
                </p>
              </div>
            </div>
           
            <br />
            <div class="html2pdf__page-break"></div>
            <div>
              <b> Attach the following documents:</b>
              <br />
              1. Student's transcript
              <br />
              2. Photocopy of parent/guardian's ID card
              <br />
              3. Photocopy of Student's National ID/Birth certificate
  
           
              <br />
              4. Institution admission
              <br />
              <br />
            </div>
  
            <div>
              <b class="text-danger">CHIEF/ASS. CHIEF</b>
              <p>
                Kindly help us verify the information given by the student. Your
                honest remarks will help in disbursing bursaries to needy students
                within our consituency since you know the student and the family
                better
              </p>
  
            
              <div v-if="responseMessage" :class="responseClass" class="response-message">
      {{ responseMessage }}
    </div>
              <!-- <div class="row">
                <div class="col-lg-6">
                  Name: .................................................
                </div>
                <div class="col-lg-6">Official stamp:</div>
                <br />
                <br />
              </div>
              <div class="row">
                <br />
                <br />
                <div class="col-lg-6">
                  Remarks: .................................................
                </div>
                <div class="col-lg-6">
                  Signature: ................................
                </div>
              </div>
            </div>
            <br />
            <br />
  
            <b> For official use only</b>
            <br />
            <br />
            <div class="row">
              <div class="col-lg-6">Approved By NG-CDF Committee</div>
              <br />
              <div class="col-lg-6">
                Amount Awarded: ...................................
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-lg-6">
                Name: .................................................
              </div>
              <br />
              <br />
              <div class="col-lg-6">
                signature : ...............................
              </div> -->
            </div>
          </div>
  
          <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
          <!-- <base-button>Download Form</base-button> -->
        </base-card>
  
        <!-- Action Buttons -->
  
        <div class="form-row">
          <div class="form-actions col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <button @click="handleSubmit">Submit Application</button>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <button @click="goBackToForm">Back to Edit</button>
          </div>
        </div>
        <!-- <div class="form-actions">
          <button @click="submitForm">Submit Application</button>
          <button @click="goBackToForm">Back to Edit</button>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
   import axios from "axios";
  
  export default {
    data() {
      return {
        isPreviewMode: false,
        showSuggestions: false,
        schoolName: "",
        form: {
          studentName: "",
          gender: "",
          yob: "",
          idNumber: "",
          schoolName: "",
          adminNumber: "",
          annualFee: "",
          student_form: "",
          yearofadmission: "",
          mother_id: "",
          mother_names: "",
          mother_yob: "",
          father_id: "",
          father_names: "",
          father_yob: "",
          guardian_id: "",
          guardian_name: "",
          guardian_yob: "",
          ward: "",
          sub_location: "",
          school_type: "",
          course:"",
          occupation:"",
          disability_status:"",
          needy_status: "",
          main_contact_number:"",
  
          //   mother: { name: "", id: "", yob: "", phone: "" },
          //   father: { name: "", id: "", yob: "", phone: "" },
          //   guardian: { name: "", id: "", yob: "", phone: "" },
        },

        responseMessage: "", // Message to display to the user
        responseClass: "", // CSS class for success/error
        //     schools: [
        //     "Katani Primary School",
        //     "Mombasa High School",
        //     "Nairobi Academy",
        //     "Nyeri High School",
        //     "St. Mary's Girls",
        //     "Green Valley Academy",
        //     "Machakos Boys High School",
        //   ],
        filteredSchools: [], // Filtered results
      };
    },
  
    computed: {
      colleges() {
        return this.$store.getters.colleges;
      },
    },
    created() {
      this.$store.dispatch("LoadColleges");
      console.log(this.colleges);
    },
  
    methods: {
      filterSchoolSuggestions() {
        // Reset the filtered list if the input is empty
        if (this.schoolName.trim() === "") {
          this.filteredSchools = [];
          return;
        }
  
        // Dynamically filter schools based on user input
        const query = this.schoolName.toLowerCase();
        this.filteredSchools = this.colleges.filter((school) =>
          school.school_name.toLowerCase().includes(query)
        );
      },
      selectSchool(school) {
        this.schoolName = school;
        this.showSuggestions = false;
      },
      hideSuggestions() {
        setTimeout(() => {
          this.showSuggestions = false;
        }, 200); // Slight delay to allow selection
      },
  
      previewForm() {
        this.isPreviewMode = true;
      },
  
      // Go back to the form to make changes
      goBackToForm() {
        this.isPreviewMode = false;
      },
      submitForm() {
        if (this.isFormValid()) {
          console.log("Form Data:", this.form);
          alert("Bursary application submitted!");
          this.resetForm();
        } else {
          alert("Please fill out all fields.");
        }
      },
      isFormValid() {
        return Object.values(this.form).every((field) =>
          typeof field === "object"
            ? Object.values(field).every((subField) => subField.trim() !== "")
            : field.trim() !== ""
        );
      },

      async handleSubmit() {
      const payload = {
        ...this.form,
        schoolName: this.schoolName,
      };
    try {
      const response = await axios.post("https://api.roberms.com/nyamoko/college/student/application", payload); 
      // const response = await axios.post("https://your-backend-endpoint/api/submit", this.formData);

      // Ensure response format handling
      if (response.data && response.data.success) {
        this.responseMessage = response.data.message || "Form submitted successfully!";
        this.responseClass = "success";
        this.resetForm();
      } else {
        this.responseMessage = response.data.message || "Submission failed. Please try again.";
        this.responseClass = "error";
      }
      await this.$router.replace("/application/form");
    } catch (error) {
      // Handle error response
      this.responseMessage = error.response.data.message || "An unexpected error occurred.";
      this.responseClass = "error";
    }
  },
  
      async createStudent() {
  
        const payload = {
          ...this.form,
          schoolName: this.schoolName,
        };
        
        const formIsValid =  this.form.studentName
        if (formIsValid) {
          const response = await axios.post(
           "https://api.roberms.com/nyamoko/college/student/application",
           payload, 
         );
         console.log(this.form);
         console.log(response);
        //  this.errors.push("Kindly fill all the fields highlighted")
         
   
         await this.$router.replace("/college/form");
         // console.log(this.formData);
  
        }
        else {
          this.errors = []
          if (!this.form.studentName){
            this.errors.push("student name is missing")
          }
          if (!this.form.schoolName){
            this.errors.push("student year of birth is missing")
          }
         
  
  
          console.log("form is invalid are invalid")
      }
      }
  
        
      //   resetForm() {
      //     this.form = {
      //       studentName: "",
      //       gender: "",
      //       yob: "",
      //       idNumber: "",
      //       schoolName: "",
      //       adminNumber: "",
      //       annualFee: "",
      //       student_form: "",
      //       yearofadmission: "",
      //       mother: { name: "", id: "", yob: "", phone: "" },
      //       father: { name: "", id: "", yob: "", phone: "" },
      //       guardian: { name: "", id: "", yob: "", phone: "" },
      //     };
      //   },
    },
  };
  </script>
  
  <style scoped>
  /* Same styling as before with some minor tweaks */
  .bursary-form {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    background: #f7faff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    color: #502c31;
  }
  
  p {
    font-size: 10px;
    color: #502c31;
  }
  
  div {
    font-size: small;
    color: #502c31;
  }
  
  .parent-group h4 {
    margin-bottom: 10px;
    color: #34495e;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .form-group {
    flex: 1;
    padding: 10px;
    background: #fefefe;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  input,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .form-actions {
    text-align: center;
  }
  
  button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background: #0056b3;
  }

  .response-message {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.response-message.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.response-message.error {
  color: #721c24;
  font-size: small;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}
  </style>
  