<template>
  <div id="body">
    <home-header></home-header>
    <!-- details {{ studentdetails }} -->

    <base-card>
        <div id="element-to-convert">
          <div class="row">
            <div class="col-lg-3">
              <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
            </div>

            <div class="col-lg-9">
              National Government Constituency Development Fund
              <br />
              North Mugirango constituency,
              <br />
              NG- CDF Office Building, Ekerenyo, Nyamira county
              <br />
              Email: ngcdfnorthmugirango@ngcdf.go.ke Website: www.cdf.go.ke
            </div>
          </div>
          <small>
            <small> 
            <p
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                
             <b class="text-primary fst-italic">FORM SERIAL NUMBER:  H{{ studentdetail.serialId }} </b> 
               
            </p>  </small> 
          </small>
          <center>
            <b class="text-danger text-center">
             2025 Bursary Application form for Secondary schools
            </b>
          </center>
          ................................................................................................................................................................................................................................................................
       <br>

          <b class="text-danger">STUDENT DETAILS</b>
          <div class="row">
            <div class="col-lg-5">
              Student name:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                
                <p class="text-capitalize text-primary">
                  {{ studentdetail.studentName }}
                </p>
              </div>
            </div>

            <div class="col-lg-3">
              Birth certificate:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.idNumber }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Main contact phone:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.main_contact_number }}
                </p>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-lg-5">
              Sub location
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.sub_location }}
                </p>
              </div>
            </div>

            <div class="col-lg-3">
              ward
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.ward }}
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              Needy status:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.needy_status }}
                </p>
              </div>
            </div>
           
          </div>

          <div class="row">
            <div class="col-lg-5">
              Institution Name:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.schoolName }}
                </p>
              </div>
            </div>

            <div class="p col-lg-3">
              School type
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_type }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Year of Admission
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.yearofadmission }}
                </p>
              </div>
            </div>
         
          </div>

          <div class="row">
            <div class="col-lg-5">
              Admission number:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.adminNumber }}
                </p>
              </div>
            </div>

            <div class="p col-lg-3">
              Class
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_form }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Dissability status
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.disability_status }}
                </p>
              </div>
            </div>
         
          </div>

          <!-- <b class="text-danger">Insitution Details</b> -->

          <div class="row"></div>
          <div class="row"></div>
          <b class="text-danger">PARENT DETAILS</b>

          <div class="row">
            <div class="col-lg-5">
              Mother name:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_names }}
                </p>
              </div>
            </div>

            <div class="col-lg-3">
              Mother Id:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_id }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Mother year of Birth:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              Father names:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_names }}
                </p>
              </div>
            </div>

            <div class="col-lg-3">
              Father Id:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_id }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Father year of Birth:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              Guardian Names:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_name }}
                </p>
              </div>
            </div>

            <div class="col-lg-3">
              Guardian Id:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_id }}
                </p>
              </div>
            </div>

            <div class="col-lg-4">
              Guardian year of Birth:
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="typed">
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col">Ocupation and narration
              <div   v-for="studentdetail in studentdetails"
              :key="studentdetail.id" class="col typed">
                <p class=" text-primary">
                   {{ studentdetail.occupation }}
                </p>
              </div>
            </div>
            </div>
         
          <br />
          <!-- <div class="html2pdf__page-break"></div> -->
          <div class="p1">
            <b> Attach the following documents:</b>
            <br />
            1. Student's transcript
            <br />
            2. Photocopy of parent/guardian's ID card
            <br />
            3. Photocopy of Student's National ID/Birth certificate

            <br />
            5. Current fee statement
            <br />
            6. School/ institution admission
            <br />
          
          </div>

          <div>
            <p class="text-danger">CHIEF/ASS. CHIEF</p>
            <p>
              Kindly help us verify the information given by the student. Your
              honest remarks will help in disbursing bursaries to needy students
              within our consituency since you know the student and the family
              better
            </p>

            <br />
            <div class="row">
              <div class="col-lg-6">
                Name: .......................................................................................
              </div>
              <div class="col-lg-6">Official stamp:</div>
              <br />
              <br />
            </div>
            <div class="row">
              <br />
              <br />
              <div class="col-lg-6">
                Remarks: ....................................................................................
              </div>
              <div class="col-lg-6">
                Signature: ..........................................................
              </div>
            </div>
          </div>
       

          <b> For official use only</b>
         
          <div class="row">
            <div class="col-lg-6">Approved By NG-CDF Committee</div>
            <br />
            <div class="col-lg-6">
              Amount Awarded: ......................................
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-lg-6">
              Name: ......................................................................
            </div>
           
            <br />
            <div class="col-lg-6">
              signature : .......................................................
            </div>
          </div>
        </div>

        <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
        <!-- <base-button>Download Form</base-button> -->
      </base-card>

      <div class="row">
        <div class="col">
          <button class="btn btn-primary" @click="exportToPDF">Download form</button>


        </div>
        <div col>
          <router-link to="/"> Go back home</router-link>

        </div>

      </div>

    
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  methods: {
    exportToPDF() {
      var element = document.getElementById("element-to-convert");
      html2pdf(element, {
        margin: [5, 15, 10, 15], //top, left, buttom, right
        filename: this.$route.params.id + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 192,
          scale: 2,
          logging: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      });
    },
  },
  created() {
    (this.idNumber = this.$route.params.id),
      this.$store.dispatch("LoadSecondarystudents");
    this.$store.dispatch("LoadVoters");
  },
  computed: {
    studentdetails() {
      return this.$store.getters.secondaryStudents.filter(id=>id.idNumber==this.$route.params.id);
    },
  },
};
</script>
<style scoped>
#body {
  width: 98vw;
  /* height: 100vw; */
  /* background-color: #eaf8d0c9; */
  position: absolute;
  margin-top: 1px;
  left: 20px;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
p {
    font-size: 12px;
    color: #502c31;
    padding: 1px;
    margin: 1px;
  }
  
  p1 {
    font-size: 13px;
    color: #502c31;
    padding: 0px;
    margin: 2px;
  }
  
  div {
    font-size: 13px;
    color: #502c31;
  }

</style>
